
.parent {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
}

.child {
    flex: .48;
}

.hideInput{
    display: none;
}