body {
  margin: 0px 0px;
  font-family: "Mulish" !important;
  font-variation-settings: "wght" 700;

}

@media screen and (max-width: 450px) {
  body {
    min-width: 115vw;
  }
}