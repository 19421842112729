.boxRoot {
    min-width: 600px;
}

.boxBody {
    margin-left: 24px;
}

.title {
    margin-top: 66px;
    padding-bottom: 27px;
    font-size: 21px;
}

.from {
    margin-bottom: 12px;
    font-size: 14px;
    font-variation-settings: "wght" 600;
}

.userInfo {
    margin-bottom: 30px;
    font-size: 14px;
    font-variation-settings: "wght" 600;
}
 
.htmlForm {
    display: flex;
    flex-direction: column;
}

.inputLabel {
    display: inline-flex;
    margin-top: 32px;
    font-size: 14px;
    padding-bottom: 15px;
    font-variation-settings: "wght" 600;
}

.inputForm {
    width: 492px;
    height: 40px;
    left: 24px;
    top: 243px;
    background: #FFFFFF;
    border: 1px solid #CFD3D3;
    border-radius: 3px;
}

.inputFormDescription {
    width: 492px;
    height: 100px;
    left: 24px;
    top: 243px;
    background: #FFFFFF;
    border: 1px solid #CFD3D3;
    border-radius: 3px;
}

.fileUploadLabel {
    input[type="file"] {
        display: none;
    }

    display: inline-flex;
    justify-content: center;
    padding: 6px 12px;
    cursor: pointer;
    width: 14em;
    border: 1px solid #CFD3D3;
    border-radius: 3px;
}

.spanFileUpload {
    display: flex;
    align-items: center;
}

.buttonGrp {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;   
}

.fileDiv {
    font-size: 12px;
    padding-top: 15px;
    font-variation-settings: "wght" 400;
}