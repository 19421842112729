.customerCard{
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.0625);
    @media screen and (max-width: 1000px) {
        margin-top: 10px;
      }
}

.customerName{
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-variation-settings: 'wght' 700;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
}
.customerInfo{
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-variation-settings: 'wght' 400;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
}