.fts_title {
    font-size: 21px;
    font-variation-settings: "wght" 700;
    line-height: 40px;
}

.appWidth {
    margin-left: 20px;
    margin-right: 50px;
}

.layoutColumns {
    @media screen and (max-width: 1000px) {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        flex-direction: column;
    }
    @media screen and (min-width: 1001px) {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
    }
}

.firstCol {
    @media screen and (min-width: 1001px) {
        float: left;
        width: 20em;
        min-width: 18em;
    }
}

.secondCol {
    width: 95em;
    float: right;
    margin-left: 1em;

    @media screen and (max-width: 1000px) {
        width: 88vw;
        min-width: 25em;
    }
}

.mobileNav {
    @media screen and (max-width: 1000px) {
        display: block;
    }
    @media screen and (min-width: 1001px) {
        display: none;
    }
}

.desktopNav {
    @media screen and (max-width: 1000px) {
        display: none;
    }
    @media screen and (min-width: 1001px) {
        display: block;
    }
}


