.appBar, .MuiAppBar-root {
    min-width: 320px;
    background-color: #FFFFFF;
    box-shadow: none !important;
    border: 1px solid #CFD3D3
}

.pageTitle {
    font-variation-settings: "wght" 300;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    min-width: 14em;
    margin-left: 16px;
}

.containerProfile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 25px 0 25px;
}

.rightContent {
    margin-left: auto;
    display: flex;
    align-items: center;
  }