
.navPanelSubClassMenu > .MuiTypography-root {
  font-size: 12px;
  margin-left: 24px;
  font-family: inherit;
  font-weight: 400;
}

.navPanelSmallTitle > .MuiTypography-root {
  font-size: 14px;
  font-family: inherit;
  font-weight: 700;
}


.Mui-selected {
  color: #336094 !important;
  background-color: #E1EDF6 !important;
}

.Mui-selected:hover {
  background-color: #F3F4F4 !important;
}
