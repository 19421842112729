.gradient-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index:0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner-wrapper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.darkened-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.2;
  z-index: 1000;
}

.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 370px;
  height: 263px;
  background-color: rgba(255,255,255,1);
  z-index: 2000;
}